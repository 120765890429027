import { forwardRef } from 'react'
import classNames from 'classnames'

type StaticImgProps = {
  className: string
  src?: string | null
  alt: string
  position?: 'absolute' | 'relative' | 'fixed'
}

const StaticImg = forwardRef<HTMLDivElement, StaticImgProps>(({ className, src, alt, position = 'relative' }, ref) => {
  return (
    <div className={classNames(position, className, 'overflow-hidden')} ref={ref}>
      <img
        loading="lazy"
        className={classNames('h-full w-full object-cover')}
        src={src ?? '/images/neubie-placeholder.png'}
        alt={alt}
      />
    </div>
  )
})

StaticImg.displayName = 'StaticImg'
export default StaticImg
