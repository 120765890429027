import { GetServerSidePropsContext } from 'next'
import { AXIOS_INSTANCE } from '@/api/mutator/custom-instance'
import { QueryKeys } from '@/constants/routes'

export const devDomains = ['dev-order', 'localhost:3000', 'localhost', 'order', undefined]

/**
 * devDomains : param이 없는 환경에서는 undefined
 *
 * @param context
 */
export const hostSettingServerSide = (context: GetServerSidePropsContext) => {
  const hostSlug = context.req.headers.host?.split('.')[0]
  const paramSlug = context.query[QueryKeys.SiteSlug] as string | undefined

  /**
   * hostSlug가 devDomain이여도 param 이 있으면 반환
   */
  if (paramSlug) {
    AXIOS_INSTANCE.defaults.headers['Site'] = paramSlug
    return paramSlug as string
  }

  /**
   * hostSlug가 devDomain 인데 paramSlug가 없으면 undefined 반환
   * hostSlug가 있으면 hostSlug 반환
   */
  if (hostSlug) {
    if (devDomains.includes(hostSlug)) {
      return
    }
    AXIOS_INSTANCE.defaults.headers['Site'] = hostSlug
    return hostSlug
  }
  return
}
