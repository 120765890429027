import {
  getShopsListQueryKey,
  getUsersProfileRetrieveQueryKey,
  useLogoutCreate,
  useSignupSocialCreate,
  useUsersProfileRetrieve
} from '@/api/generated/hooks'
import { SocialLoginTypeEnum, SocialSignupRequest, TokenRefreshRes } from '@/api/generated/types'
import { QueryStatusEnum, setStorageSiteName } from '@/api/mutator/custom-instance'
import { QueryKeys, Routes } from '@/constants/routes'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isServer } from '@/pages/_app'

type SocialDataType = {
  refreshToken: string
  socialLoginType: SocialLoginTypeEnum
}

const loginConfirmRequest = () => {
  const siteParam = setStorageSiteName()
  // accessToken 발급은 쿠키에 저장된 refreshToken으로 하기에 별도 데이터가 없어도 됩니다.
  return axios.post<TokenRefreshRes>(process.env.API_DOMAIN + `/token/refresh/`, undefined, {
    withCredentials: true,
    headers: {
      ...(siteParam && { Site: siteParam })
    }
  })
}

export const isEntryPointDelivery = () => {
  return isServer
    ? false
    : SessionStorage.getItem<string>(SessionStorageKeyEnum.BeforeRedirectUrl) === Routes.DeliveryDocument
}

const useAuthHook = () => {
  const { replace } = useRouter()
  const queryClient = useQueryClient()
  const [signInSocialData, setSignInSocialData] = useState<SocialDataType>()
  const [socialSignupData, setSocialSignupData] = useState<SocialSignupRequest>()
  /**
   * TODO: isLogin을 LoginStatusEnum으로 변경해야 합니다.
   * LoginStatusEnum.Init      : 초기 상태         기존 값 undefined
   * LoginStatusEnum.Login     : 로그인 된 상태     기존 값이 true
   * LoginStatusEnum.Logout    : 로그아웃 된 상태    기존 값이 false
   * LoginStatusEnum.Loading   : 로그인 중인 상태    기존 값이 undefined
   */
  const [isLogin, setIsLogin] = useState<boolean>()
  const { mutate: logout } = useLogoutCreate()
  const { data: tokenRes, status: isLoginStatus } = useQuery({
    queryKey: ['/token/refresh/'],
    queryFn: loginConfirmRequest,
    retry: 0,
    cacheTime: 0
  })
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    refetch: refetchUserProfile
  } = useUsersProfileRetrieve({
    query: {
      enabled: !!isLogin
    }
  })

  useEffect(() => {
    const processLogin = async () => {
      if (isLoginStatus === QueryStatusEnum.Success) {
        localStorage.setItem(LocalStorageKeyEnum.AccessToken, tokenRes.data.accessToken)
        await queryClient.invalidateQueries(getShopsListQueryKey())
        setIsLogin(true)
        return
      }
      if (isLoginStatus === QueryStatusEnum.Loading) {
        setIsLogin(undefined)
        return
      }
      if (isLoginStatus === QueryStatusEnum.Error) {
        localStorage.removeItem(LocalStorageKeyEnum.AccessToken)
        LocalStorage.removeItem(LocalStorageKeyEnum.SocialLoginType)
        LocalStorage.removeItem(LocalStorageKeyEnum.AddCartComplete)
        setIsLogin(false)
        return
      }
    }

    processLogin()
  }, [isLoginStatus, tokenRes])

  const logoutSequence = async () => {
    await logout({
      data: {
        deviceNumber: undefined
      }
    })
    setIsLogin(false)

    LocalStorage.removeItem(LocalStorageKeyEnum.AccessToken)
    LocalStorage.removeItem(LocalStorageKeyEnum.SocialLoginType)
    LocalStorage.removeItem(LocalStorageKeyEnum.AddCartComplete)
    SessionStorage.removeItem(SessionStorageKeyEnum.FailMutateRequestConfigList)
    SessionStorage.removeItem(SessionStorageKeyEnum.IsFirstRenderRobotStatusSheet)
    SessionStorage.removeItem(SessionStorageKeyEnum.CreatedOrder)
    await queryClient.invalidateQueries(getShopsListQueryKey())
    await queryClient.invalidateQueries(getUsersProfileRetrieveQueryKey())

    replace({
      pathname: Routes.Login,
      query: {
        [QueryKeys.LogoutFlag]: true
      }
    })
  }

  const signInKakao = () => {
    const redirectUri = `${window?.location?.origin}/login/kakao-callback`
    const clientId = process.env.KAKAO_REST_KEY
    if (!redirectUri || !clientId) {
      console.error('redirectUri, clientId not found')
      return
    }
    const kakaoAuthUrl = 'https://kauth.kakao.com/oauth/authorize'
    location.replace(`${kakaoAuthUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`)
  }

  // localStorage에 accessToken이 변경되는 것을 감지하여 로그인 상태를 업데이트 합니다.
  const handleChangeLoginStatus = () => {
    const isLogin = !!localStorage.getItem(LocalStorageKeyEnum.AccessToken)
    setIsLogin(isLogin)
  }

  // 회원가입 signUp
  const { mutate: signupSocialMutate } = useSignupSocialCreate({
    mutation: {
      onSuccess: async (data) => {
        LocalStorage.setItem(LocalStorageKeyEnum.AccessToken, data.accessToken)
        setIsLogin(true)
        if (isEntryPointDelivery()) {
          await replace({
            pathname: Routes.VerifyMobile,
            query: {
              [QueryKeys.Redirect]: Routes.RegisterFinish
            }
          })
        } else {
          await replace({
            pathname: Routes.RegisterFinish,
            query: {
              [QueryKeys.PointPaid]: data.point
            }
          })
        }
      },
      onError: (error) => {
        console.error(error)
      }
    }
  })

  // 회원가입 요청
  const signupSocial = () => {
    if (!socialSignupData) {
      return
    }
    signupSocialMutate({ data: { ...socialSignupData } })
  }

  useEffect(() => {
    window.addEventListener('storage', handleChangeLoginStatus)
    return () => {
      window.removeEventListener('storage', handleChangeLoginStatus)
    }
  }, [])

  return {
    logoutSequence,
    signInKakao,
    signInSocialData,
    setSignInSocialData,
    isLogin,
    setIsLogin,
    userProfile,
    isUserProfileLoading,
    signupSocial,
    socialSignupData,
    refetchUserProfile,
    setSocialSignupData
  }
}

const AuthContainer = createContainer(useAuthHook)

export default AuthContainer
