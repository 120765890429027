import Layout from '@/components/layouts/Layout'
import AuthContainer from '@/containers/app/AuthContainer'
import '@/styles/globals.css'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import Head from 'next/head'
import ScrollSaveContainer from '@/containers/ScrollSaveContainer'
import { Hydrate, QueryCache, QueryClientProvider } from '@tanstack/react-query'
import { QueryClient } from '@tanstack/query-core'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import RootMetaTags from '@/components/meta/RootMetaTags'
import DeliveryDocumentInfoContainer from '@/containers/domains/DeliveryDocumentInfoContainer'
import { DeployEnvironmentEnum } from '@/constants/deployEnvironment.enum'

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export const isSite = (slug?: string | null) => {
  if (isServer) {
    return false
  }
  return LocalStorage.getItem(LocalStorageKeyEnum.SiteSlug) === slug
}
export const isServer = typeof window === 'undefined'

const runMockWorker = async () => {
  const { worker } = await import('@/api/mock/mockWorker')
  worker?.start?.()
}

if (!isServer && process.env.MOCK_ENABLE) {
  runMockWorker()
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools
  }))
)

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const queryCache = new QueryCache()
  const [showDevtools, setShowDevtools] = useState(false)

  /**
   * 기본 cacheTime을 0으로 두면 serverside hydration이 되기전에 api 요청후 리렌더링 되므로
   * 이중렌더링 될 수 있습니다.
   */
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 0,
            retryDelay: 300
          }
        }
      })
  )

  // TODO 스마트테크전시회 체험용 때문에 dev 서버에서 제거.
  //  추후 다시 생성 예정
  useEffect(() => {
    if (process.env.NEWBIE_ORDER_ENV === DeployEnvironmentEnum.Local) {
      setShowDevtools(true)
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps && pageProps.dehydratedState}>
        {showDevtools && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction position="bottom-right" />
          </React.Suspense>
        )}
        <ScrollSaveContainer.Provider>
          <AuthContainer.Provider>
            <DeliveryDocumentInfoContainer.Provider>
              <Head>
                <RootMetaTags />
              </Head>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </DeliveryDocumentInfoContainer.Provider>
          </AuthContainer.Provider>
        </ScrollSaveContainer.Provider>
      </Hydrate>
    </QueryClientProvider>
  )
}
