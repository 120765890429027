import { createContainer } from 'unstated-next'
import { useEffect, useState } from 'react'
import { DropDownContentType } from '@/hooks/common/useDropDown'
import { useRouter } from 'next/router'
import { Routes } from '@/constants/routes'

export type DocumentDeliveryUserType = {
  name?: string
  phoneNumber?: string
  isChecked: boolean
}

export type OrderDocumentFormType = {
  sender?: DocumentDeliveryUserType
  recipient?: DocumentDeliveryUserType
  userNote?: string
}

const useDeliveryDocumentInfo = () => {
  const { pathname } = useRouter()
  const [departureInfo, setDepartureInfo] = useState<DropDownContentType>()
  const [arrivalInfo, setArrivalInfo] = useState<DropDownContentType>()
  const [orderDocumentFormData, setOrderDocumentFormData] = useState<OrderDocumentFormType>()

  useEffect(() => {
    setOrderDocumentFormData(undefined)
  }, [departureInfo, arrivalInfo])

  useEffect(() => {
    switch (pathname) {
      case Routes.DeliveryDocument:
        return
      case Routes.OrderDocument:
        return
      default:
        setDepartureInfo(undefined)
        setArrivalInfo(undefined)
        setOrderDocumentFormData(undefined)
    }
  }, [pathname])

  return {
    departureInfo,
    setDepartureInfo,
    arrivalInfo,
    setArrivalInfo,
    orderDocumentFormData,
    setOrderDocumentFormData
  }
}

const DeliveryDocumentInfoContainer = createContainer(useDeliveryDocumentInfo)

export default DeliveryDocumentInfoContainer
