/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Neubie Order
 * Neubie Order API Server
 * OpenAPI spec version: V1
 */
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AppVersionNeedUpdateRes,
  AppVersionsNeedUpdateRetrieveParams,
  CartAddReqRequest,
  CartCountRes,
  CartCreate400Exception,
  CartRes,
  CartUpdateQuantityReqRequest,
  CartUpdateQuantityRes,
  CartsListParams,
  CountriesListParams,
  DevicePushTokenReqRequest,
  DeviceRes,
  FreeDeliveryRes,
  LoginReqRequest,
  LoginRes,
  LogoutReqRequest,
  MyPageRes,
  NeomPromotionInfoRes,
  NeomPromotionRegister400Exception,
  NeomPromotionRegisterReqRequest,
  NeomPromotionRegisterRes,
  NodeRes,
  OrderCouponUpdateReqRequest,
  OrderCreate400Exception,
  OrderDetailRes,
  OrderDocument400Exception,
  OrderDocumentReqRequest,
  OrderDocumentRes,
  OrderEstimatePath400Exception,
  OrderEstimatePathRes,
  OrderPartialUpdate400Exception,
  OrderReceiptRes,
  OrderRes,
  OrderReviewCreate400Exception,
  OrderReviewCreateRequest,
  OrderReviewRes,
  OrdersEstimatePathRetrieveParams,
  OrdersListParams,
  PaginatedCartResList,
  PaginatedCountryResList,
  PaginatedCouponResList,
  PaginatedOrderHistoryResList,
  PaginatedProductCategoryProductsResList,
  PaginatedProductCategoryResList,
  PaginatedShopResList,
  PaginatedSiteResList,
  PaginatedUserCouponResList,
  PatchedCartOrderPartialUpdateReqRequest,
  ProductDetailRes,
  ProductsCategoriesListParams,
  ProductsCategoriesProductsListParams,
  QrCodeReqRequest,
  ShopRes,
  ShopRetrieveRes,
  ShopRetrieveRes403Exception,
  ShopsCouponsListParams,
  ShopsListParams,
  SiteBottomSheetRes,
  SiteDispatchStatusRes,
  SiteRes,
  SitesBottomSheetsListParams,
  SitesListParams,
  SocialLoginRequest,
  SocialLoginRes,
  SocialSignup401Exception,
  SocialSignupRequest,
  SocialSignupRes,
  TapCardInitiatedRes,
  TapCardListRes,
  TapPaymentPayReqRequest,
  TapPaymentReq400Exception,
  TapPaymentReq404Exception,
  TokenRefreshRes,
  TotalPriceRes,
  TransactionPaymentResultRes,
  TransactionPaymentStatusRes,
  UserConfirmVerificationReqRequest,
  UserConfirmVerificationRes,
  UserCouponReqRequest,
  UserPushSubscriptionCreate400Exception,
  UserPushSubscriptionGetRes,
  UserPushSubscriptionReqRequest,
  UserPushSubscriptionRes,
  UserRequestVerification400Exception,
  UserRequestVerificationReqRequest,
  UserRequestVerificationRes,
  UserWalletPaymentReqRequest,
  UserWalletPaymentRes,
  UserWalletRes,
  UsersCouponsCreateParams,
  UsersCouponsListParams,
  VoucherCreateReqRequest,
  VoucherRegisterReqRequest,
  VoucherRegisterRes,
  VoucherRes
} from './types'
import { customInstance } from '../mutator/custom-instance'
import type { BodyType, ErrorType } from '../mutator/custom-instance'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

/**
 * 앱 버전 업데이트 유무를 확인합니다.

권한: 누구나
 * @summary 앱 버전 업데이트 유무 확인
 */
export const appVersionsNeedUpdateRetrieve = (
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AppVersionNeedUpdateRes>(
    { url: `/app-versions/need-update/`, method: 'get', params, signal },
    options
  )
}

export const getAppVersionsNeedUpdateRetrieveQueryKey = (params: AppVersionsNeedUpdateRetrieveParams) => [
  `/app-versions/need-update/`,
  ...(params ? [params] : [])
]

export type AppVersionsNeedUpdateRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>
>
export type AppVersionsNeedUpdateRetrieveInfiniteQueryError = ErrorType<unknown>

export const useAppVersionsNeedUpdateRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAppVersionsNeedUpdateRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>> = ({ signal }) =>
    appVersionsNeedUpdateRetrieve(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type AppVersionsNeedUpdateRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>
>
export type AppVersionsNeedUpdateRetrieveQueryError = ErrorType<unknown>

export const useAppVersionsNeedUpdateRetrieve = <
  TData = Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>,
  TError = ErrorType<unknown>
>(
  params: AppVersionsNeedUpdateRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAppVersionsNeedUpdateRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>> = ({ signal }) =>
    appVersionsNeedUpdateRetrieve(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof appVersionsNeedUpdateRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Tap 카드리스트 조회
 * @summary Tap 카드리스트 조회
 */
export const cardsTapList = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<TapCardListRes[]>({ url: `/cards/tap/`, method: 'get', signal }, options)
}

export const getCardsTapListQueryKey = () => [`/cards/tap/`]

export type CardsTapListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof cardsTapList>>>
export type CardsTapListInfiniteQueryError = ErrorType<unknown>

export const useCardsTapListInfinite = <
  TData = Awaited<ReturnType<typeof cardsTapList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof cardsTapList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCardsTapListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cardsTapList>>> = ({ signal }) =>
    cardsTapList(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof cardsTapList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type CardsTapListQueryResult = NonNullable<Awaited<ReturnType<typeof cardsTapList>>>
export type CardsTapListQueryError = ErrorType<unknown>

export const useCardsTapList = <
  TData = Awaited<ReturnType<typeof cardsTapList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof cardsTapList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCardsTapListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cardsTapList>>> = ({ signal }) =>
    cardsTapList(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof cardsTapList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 장바구니 목록 조회
 * @summary 장바구니 목록 조회
 */
export const cartsList = (
  params?: CartsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedCartResList>({ url: `/carts/`, method: 'get', params, signal }, options)
}

export const getCartsListQueryKey = (params?: CartsListParams) => [`/carts/`, ...(params ? [params] : [])]

export type CartsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof cartsList>>>
export type CartsListInfiniteQueryError = ErrorType<unknown>

export const useCartsListInfinite = <TData = Awaited<ReturnType<typeof cartsList>>, TError = ErrorType<unknown>>(
  params?: CartsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof cartsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsList>>> = ({ signal }) =>
    cartsList(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof cartsList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type CartsListQueryResult = NonNullable<Awaited<ReturnType<typeof cartsList>>>
export type CartsListQueryError = ErrorType<unknown>

export const useCartsList = <TData = Awaited<ReturnType<typeof cartsList>>, TError = ErrorType<unknown>>(
  params?: CartsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof cartsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsList>>> = ({ signal }) =>
    cartsList(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof cartsList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 장바구니 생성
 * @summary 장바구니 생성
 */
export const cartsCreate = (
  cartAddReqRequest: BodyType<CartAddReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CartRes>(
    { url: `/carts/`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: cartAddReqRequest },
    options
  )
}

export type CartsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof cartsCreate>>>
export type CartsCreateMutationBody = BodyType<CartAddReqRequest>
export type CartsCreateMutationError = ErrorType<CartCreate400Exception>

export const useCartsCreate = <TError = ErrorType<CartCreate400Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cartsCreate>>,
    TError,
    { data: BodyType<CartAddReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cartsCreate>>, { data: BodyType<CartAddReqRequest> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return cartsCreate(data, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof cartsCreate>>, TError, { data: BodyType<CartAddReqRequest> }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 장바구니 상품 제거
 * @summary 장바구니 상품 제거
 */
export const cartsDestroy = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/carts/${id}/`, method: 'delete' }, options)
}

export type CartsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof cartsDestroy>>>

export type CartsDestroyMutationError = ErrorType<unknown>

export const useCartsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cartsDestroy>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cartsDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return cartsDestroy(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof cartsDestroy>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 장바구니 상품 수량 변경
 * @summary 장바구니 상품 수량 변경
 */
export const cartsQuantityUpdate = (
  id: number,
  cartUpdateQuantityReqRequest: BodyType<CartUpdateQuantityReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<CartUpdateQuantityRes>(
    {
      url: `/carts/${id}/quantity/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: cartUpdateQuantityReqRequest
    },
    options
  )
}

export type CartsQuantityUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof cartsQuantityUpdate>>>
export type CartsQuantityUpdateMutationBody = BodyType<CartUpdateQuantityReqRequest>
export type CartsQuantityUpdateMutationError = ErrorType<unknown>

export const useCartsQuantityUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cartsQuantityUpdate>>,
    TError,
    { id: number; data: BodyType<CartUpdateQuantityReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cartsQuantityUpdate>>,
    { id: number; data: BodyType<CartUpdateQuantityReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return cartsQuantityUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof cartsQuantityUpdate>>,
    TError,
    { id: number; data: BodyType<CartUpdateQuantityReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 장바구니 전체 삭제
 * @summary 장바구니 전체 삭제
 */
export const cartsAllDestroy = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/carts/all/`, method: 'delete' }, options)
}

export type CartsAllDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof cartsAllDestroy>>>

export type CartsAllDestroyMutationError = ErrorType<unknown>

export const useCartsAllDestroy = <TError = ErrorType<unknown>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cartsAllDestroy>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cartsAllDestroy>>, TVariables> = () => {
    return cartsAllDestroy(requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof cartsAllDestroy>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 장바구니 갯수 조회
 * @summary 장바구니 갯수 조회
 */
export const cartsCountRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<CartCountRes>({ url: `/carts/count/`, method: 'get', signal }, options)
}

export const getCartsCountRetrieveQueryKey = () => [`/carts/count/`]

export type CartsCountRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof cartsCountRetrieve>>>
export type CartsCountRetrieveInfiniteQueryError = ErrorType<unknown>

export const useCartsCountRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof cartsCountRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof cartsCountRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsCountRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsCountRetrieve>>> = ({ signal }) =>
    cartsCountRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof cartsCountRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type CartsCountRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof cartsCountRetrieve>>>
export type CartsCountRetrieveQueryError = ErrorType<unknown>

export const useCartsCountRetrieve = <
  TData = Awaited<ReturnType<typeof cartsCountRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof cartsCountRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsCountRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsCountRetrieve>>> = ({ signal }) =>
    cartsCountRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof cartsCountRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 장바구니에서 샵 조회
 * @summary 장바구니에서 샵 조회
 */
export const cartsShopRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ShopRes>({ url: `/carts/shop/`, method: 'get', signal }, options)
}

export const getCartsShopRetrieveQueryKey = () => [`/carts/shop/`]

export type CartsShopRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof cartsShopRetrieve>>>
export type CartsShopRetrieveInfiniteQueryError = ErrorType<unknown>

export const useCartsShopRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof cartsShopRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof cartsShopRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsShopRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsShopRetrieve>>> = ({ signal }) =>
    cartsShopRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof cartsShopRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type CartsShopRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof cartsShopRetrieve>>>
export type CartsShopRetrieveQueryError = ErrorType<unknown>

export const useCartsShopRetrieve = <
  TData = Awaited<ReturnType<typeof cartsShopRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof cartsShopRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsShopRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsShopRetrieve>>> = ({ signal }) =>
    cartsShopRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof cartsShopRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 장바구니 총액
 * @summary 장바구니 총액
 */
export const cartsTotalPriceRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<TotalPriceRes>({ url: `/carts/total-price/`, method: 'get', signal }, options)
}

export const getCartsTotalPriceRetrieveQueryKey = () => [`/carts/total-price/`]

export type CartsTotalPriceRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>
>
export type CartsTotalPriceRetrieveInfiniteQueryError = ErrorType<unknown>

export const useCartsTotalPriceRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsTotalPriceRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>> = ({ signal }) =>
    cartsTotalPriceRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type CartsTotalPriceRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>>
export type CartsTotalPriceRetrieveQueryError = ErrorType<unknown>

export const useCartsTotalPriceRetrieve = <
  TData = Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCartsTotalPriceRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>> = ({ signal }) =>
    cartsTotalPriceRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof cartsTotalPriceRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const countriesList = (
  params?: CountriesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedCountryResList>({ url: `/countries/`, method: 'get', params, signal }, options)
}

export const getCountriesListQueryKey = (params?: CountriesListParams) => [`/countries/`, ...(params ? [params] : [])]

export type CountriesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof countriesList>>>
export type CountriesListInfiniteQueryError = ErrorType<unknown>

export const useCountriesListInfinite = <
  TData = Awaited<ReturnType<typeof countriesList>>,
  TError = ErrorType<unknown>
>(
  params?: CountriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCountriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countriesList>>> = ({ signal }) =>
    countriesList(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof countriesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type CountriesListQueryResult = NonNullable<Awaited<ReturnType<typeof countriesList>>>
export type CountriesListQueryError = ErrorType<unknown>

export const useCountriesList = <TData = Awaited<ReturnType<typeof countriesList>>, TError = ErrorType<unknown>>(
  params?: CountriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof countriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCountriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countriesList>>> = ({ signal }) =>
    countriesList(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof countriesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Push Token 등록/수정
 * @summary Push Token 등록/수정
 */
export const devicesPushTokenUpdate = (
  devicePushTokenReqRequest: BodyType<DevicePushTokenReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DeviceRes>(
    {
      url: `/devices/push-token/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: devicePushTokenReqRequest
    },
    options
  )
}

export type DevicesPushTokenUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof devicesPushTokenUpdate>>>
export type DevicesPushTokenUpdateMutationBody = BodyType<DevicePushTokenReqRequest>
export type DevicesPushTokenUpdateMutationError = ErrorType<unknown>

export const useDevicesPushTokenUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof devicesPushTokenUpdate>>,
    TError,
    { data: BodyType<DevicePushTokenReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof devicesPushTokenUpdate>>,
    { data: BodyType<DevicePushTokenReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return devicesPushTokenUpdate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof devicesPushTokenUpdate>>,
    TError,
    { data: BodyType<DevicePushTokenReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 로그인
 * @deprecated
 * @summary 로그인
 */
export const loginCreate = (
  loginReqRequest: BodyType<LoginReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<LoginRes>(
    { url: `/login/`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: loginReqRequest },
    options
  )
}

export type LoginCreateMutationResult = NonNullable<Awaited<ReturnType<typeof loginCreate>>>
export type LoginCreateMutationBody = BodyType<LoginReqRequest>
export type LoginCreateMutationError = ErrorType<unknown>

export const useLoginCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginCreate>>,
    TError,
    { data: BodyType<LoginReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginCreate>>, { data: BodyType<LoginReqRequest> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return loginCreate(data, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof loginCreate>>, TError, { data: BodyType<LoginReqRequest> }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 소셜 로그인
 * @summary 소셜 로그인
 */
export const loginSocialCreate = (
  socialLoginRequest: BodyType<SocialLoginRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SocialLoginRes>(
    {
      url: `/login/social/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: socialLoginRequest
    },
    options
  )
}

export type LoginSocialCreateMutationResult = NonNullable<Awaited<ReturnType<typeof loginSocialCreate>>>
export type LoginSocialCreateMutationBody = BodyType<SocialLoginRequest>
export type LoginSocialCreateMutationError = ErrorType<unknown>

export const useLoginSocialCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loginSocialCreate>>,
    TError,
    { data: BodyType<SocialLoginRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loginSocialCreate>>,
    { data: BodyType<SocialLoginRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return loginSocialCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof loginSocialCreate>>,
    TError,
    { data: BodyType<SocialLoginRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 로그아웃
 * @deprecated
 * @summary 로그아웃
 */
export const logoutCreate = (
  logoutReqRequest: BodyType<LogoutReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    { url: `/logout/`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: logoutReqRequest },
    options
  )
}

export type LogoutCreateMutationResult = NonNullable<Awaited<ReturnType<typeof logoutCreate>>>
export type LogoutCreateMutationBody = BodyType<LogoutReqRequest>
export type LogoutCreateMutationError = ErrorType<unknown>

export const useLogoutCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logoutCreate>>,
    TError,
    { data: BodyType<LogoutReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof logoutCreate>>, { data: BodyType<LogoutReqRequest> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return logoutCreate(data, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof logoutCreate>>, TError, { data: BodyType<LogoutReqRequest> }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 네옴 프로모션 정보 확인. 예) 기본 포인트

권한: 누구나
 * @summary 네옴 프로모션 정보 확인
 */
export const neomPromotionsInfoRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<NeomPromotionInfoRes>({ url: `/neom-promotions/info/`, method: 'get', signal }, options)
}

export const getNeomPromotionsInfoRetrieveQueryKey = () => [`/neom-promotions/info/`]

export type NeomPromotionsInfoRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>
>
export type NeomPromotionsInfoRetrieveInfiniteQueryError = ErrorType<unknown>

export const useNeomPromotionsInfoRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNeomPromotionsInfoRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>> = ({ signal }) =>
    neomPromotionsInfoRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type NeomPromotionsInfoRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>>
export type NeomPromotionsInfoRetrieveQueryError = ErrorType<unknown>

export const useNeomPromotionsInfoRetrieve = <
  TData = Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNeomPromotionsInfoRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>> = ({ signal }) =>
    neomPromotionsInfoRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof neomPromotionsInfoRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 네옴 프로모션 등록

권한: 로그인한 사용자
 * @summary 네옴 프로모션 등록
 */
export const neomPromotionsRegisterCreate = (
  neomPromotionRegisterReqRequest: BodyType<NeomPromotionRegisterReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<NeomPromotionRegisterRes>(
    {
      url: `/neom-promotions/register/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: neomPromotionRegisterReqRequest
    },
    options
  )
}

export type NeomPromotionsRegisterCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof neomPromotionsRegisterCreate>>
>
export type NeomPromotionsRegisterCreateMutationBody = BodyType<NeomPromotionRegisterReqRequest>
export type NeomPromotionsRegisterCreateMutationError = ErrorType<NeomPromotionRegister400Exception>

export const useNeomPromotionsRegisterCreate = <
  TError = ErrorType<NeomPromotionRegister400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof neomPromotionsRegisterCreate>>,
    TError,
    { data: BodyType<NeomPromotionRegisterReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof neomPromotionsRegisterCreate>>,
    { data: BodyType<NeomPromotionRegisterReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return neomPromotionsRegisterCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof neomPromotionsRegisterCreate>>,
    TError,
    { data: BodyType<NeomPromotionRegisterReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

export const nodesList = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<NodeRes[]>({ url: `/nodes/`, method: 'get', signal }, options)
}

export const getNodesListQueryKey = () => [`/nodes/`]

export type NodesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof nodesList>>>
export type NodesListInfiniteQueryError = ErrorType<unknown>

export const useNodesListInfinite = <
  TData = Awaited<ReturnType<typeof nodesList>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesList>>> = ({ signal }) =>
    nodesList(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof nodesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type NodesListQueryResult = NonNullable<Awaited<ReturnType<typeof nodesList>>>
export type NodesListQueryError = ErrorType<unknown>

export const useNodesList = <TData = Awaited<ReturnType<typeof nodesList>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof nodesList>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getNodesListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof nodesList>>> = ({ signal }) =>
    nodesList(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof nodesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 주문 목록 조회
 * @summary 주문 목록 조회
 */
export const ordersList = (
  params?: OrdersListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedOrderHistoryResList>({ url: `/orders/`, method: 'get', params, signal }, options)
}

export const getOrdersListQueryKey = (params?: OrdersListParams) => [`/orders/`, ...(params ? [params] : [])]

export type OrdersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersList>>>
export type OrdersListInfiniteQueryError = ErrorType<unknown>

export const useOrdersListInfinite = <TData = Awaited<ReturnType<typeof ordersList>>, TError = ErrorType<unknown>>(
  params?: OrdersListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersList>>> = ({ signal }) =>
    ordersList(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof ordersList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type OrdersListQueryResult = NonNullable<Awaited<ReturnType<typeof ordersList>>>
export type OrdersListQueryError = ErrorType<unknown>

export const useOrdersList = <TData = Awaited<ReturnType<typeof ordersList>>, TError = ErrorType<unknown>>(
  params?: OrdersListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersList>>> = ({ signal }) =>
    ordersList(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof ordersList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export const ordersRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<OrderRes>({ url: `/orders/${id}/`, method: 'get', signal }, options)
}

export const getOrdersRetrieveQueryKey = (id: number) => [`/orders/${id}/`]

export type OrdersRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersRetrieve>>>
export type OrdersRetrieveInfiniteQueryError = ErrorType<unknown>

export const useOrdersRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof ordersRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersRetrieve>>> = ({ signal }) =>
    ordersRetrieve(id, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type OrdersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof ordersRetrieve>>>
export type OrdersRetrieveQueryError = ErrorType<unknown>

export const useOrdersRetrieve = <TData = Awaited<ReturnType<typeof ordersRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersRetrieve>>> = ({ signal }) =>
    ordersRetrieve(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof ordersRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 주문 수정
 * @summary 주문 수정
 */
export const ordersPartialUpdate = (
  id: number,
  patchedCartOrderPartialUpdateReqRequest: BodyType<PatchedCartOrderPartialUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderRes>(
    {
      url: `/orders/${id}/`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: patchedCartOrderPartialUpdateReqRequest
    },
    options
  )
}

export type OrdersPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersPartialUpdate>>>
export type OrdersPartialUpdateMutationBody = BodyType<PatchedCartOrderPartialUpdateReqRequest>
export type OrdersPartialUpdateMutationError = ErrorType<OrderPartialUpdate400Exception>

export const useOrdersPartialUpdate = <
  TError = ErrorType<OrderPartialUpdate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedCartOrderPartialUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersPartialUpdate>>,
    { id: number; data: BodyType<PatchedCartOrderPartialUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return ordersPartialUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof ordersPartialUpdate>>,
    TError,
    { id: number; data: BodyType<PatchedCartOrderPartialUpdateReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 사용자가 주문 취소합니다. 문서 배달에서 사용 가능합니다.
 * @summary 주문 취소
 */
export const ordersCancelUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/orders/${id}/cancel/`, method: 'put' }, options)
}

export type OrdersCancelUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersCancelUpdate>>>

export type OrdersCancelUpdateMutationError = ErrorType<unknown>

export const useOrdersCancelUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersCancelUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersCancelUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return ordersCancelUpdate(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof ordersCancelUpdate>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 주문 상세 조회
 * @summary 주문 상세 조회
 */
export const ordersDetailRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderDetailRes>({ url: `/orders/${id}/detail/`, method: 'get', signal }, options)
}

export const getOrdersDetailRetrieveQueryKey = (id: number) => [`/orders/${id}/detail/`]

export type OrdersDetailRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersDetailRetrieve>>>
export type OrdersDetailRetrieveInfiniteQueryError = ErrorType<unknown>

export const useOrdersDetailRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof ordersDetailRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersDetailRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersDetailRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersDetailRetrieve>>> = ({ signal }) =>
    ordersDetailRetrieve(id, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof ordersDetailRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type OrdersDetailRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof ordersDetailRetrieve>>>
export type OrdersDetailRetrieveQueryError = ErrorType<unknown>

export const useOrdersDetailRetrieve = <
  TData = Awaited<ReturnType<typeof ordersDetailRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersDetailRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersDetailRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersDetailRetrieve>>> = ({ signal }) =>
    ordersDetailRetrieve(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof ordersDetailRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 뚜껑 열기
 * @summary 뚜껑 열기
 */
export const ordersOpenCoverUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/orders/${id}/open-cover/`, method: 'put' }, options)
}

export type OrdersOpenCoverUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>>

export type OrdersOpenCoverUpdateMutationError = ErrorType<unknown>

export const useOrdersOpenCoverUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return ordersOpenCoverUpdate(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof ordersOpenCoverUpdate>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 주문 영수증 조회
 * @summary 주문 영수증 조회
 */
export const ordersReceiptRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderReceiptRes>({ url: `/orders/${id}/receipt/`, method: 'get', signal }, options)
}

export const getOrdersReceiptRetrieveQueryKey = (id: number) => [`/orders/${id}/receipt/`]

export type OrdersReceiptRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersReceiptRetrieve>>>
export type OrdersReceiptRetrieveInfiniteQueryError = ErrorType<unknown>

export const useOrdersReceiptRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof ordersReceiptRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersReceiptRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersReceiptRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersReceiptRetrieve>>> = ({ signal }) =>
    ordersReceiptRetrieve(id, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof ordersReceiptRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type OrdersReceiptRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof ordersReceiptRetrieve>>>
export type OrdersReceiptRetrieveQueryError = ErrorType<unknown>

export const useOrdersReceiptRetrieve = <
  TData = Awaited<ReturnType<typeof ordersReceiptRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersReceiptRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersReceiptRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersReceiptRetrieve>>> = ({ signal }) =>
    ordersReceiptRetrieve(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof ordersReceiptRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 주문 리뷰를 조회합니다.
 * @summary 주문 리뷰 조회
 */
export const ordersReviewRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderReviewRes>({ url: `/orders/${id}/review/`, method: 'get', signal }, options)
}

export const getOrdersReviewRetrieveQueryKey = (id: number) => [`/orders/${id}/review/`]

export type OrdersReviewRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof ordersReviewRetrieve>>>
export type OrdersReviewRetrieveInfiniteQueryError = ErrorType<unknown>

export const useOrdersReviewRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof ordersReviewRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersReviewRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersReviewRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersReviewRetrieve>>> = ({ signal }) =>
    ordersReviewRetrieve(id, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof ordersReviewRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type OrdersReviewRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof ordersReviewRetrieve>>>
export type OrdersReviewRetrieveQueryError = ErrorType<unknown>

export const useOrdersReviewRetrieve = <
  TData = Awaited<ReturnType<typeof ordersReviewRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersReviewRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersReviewRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersReviewRetrieve>>> = ({ signal }) =>
    ordersReviewRetrieve(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof ordersReviewRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 주문 리뷰를 추가합니다.
 * @summary 주문 리뷰 추가
 */
export const ordersReviewCreate = (
  id: number,
  orderReviewCreateRequest: BodyType<OrderReviewCreateRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderReviewRes>(
    {
      url: `/orders/${id}/review/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: orderReviewCreateRequest
    },
    options
  )
}

export type OrdersReviewCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersReviewCreate>>>
export type OrdersReviewCreateMutationBody = BodyType<OrderReviewCreateRequest>
export type OrdersReviewCreateMutationError = ErrorType<OrderReviewCreate400Exception>

export const useOrdersReviewCreate = <TError = ErrorType<OrderReviewCreate400Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersReviewCreate>>,
    TError,
    { id: number; data: BodyType<OrderReviewCreateRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersReviewCreate>>,
    { id: number; data: BodyType<OrderReviewCreateRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return ordersReviewCreate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof ordersReviewCreate>>,
    TError,
    { id: number; data: BodyType<OrderReviewCreateRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 배송 시작
 * @summary 배송 시작
 */
export const ordersStartDeliveryUpdate = (id: number, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/orders/${id}/start-delivery/`, method: 'put' }, options)
}

export type OrdersStartDeliveryUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>>

export type OrdersStartDeliveryUpdateMutationError = ErrorType<unknown>

export const useOrdersStartDeliveryUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>, TError, { id: number }, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>, { id: number }> = (
    props
  ) => {
    const { id } = props ?? {}

    return ordersStartDeliveryUpdate(id, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof ordersStartDeliveryUpdate>>, TError, { id: number }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 주문 쿠폰 적용
 * @summary 주문 쿠폰 적용
 */
export const ordersUserCouponUpdate = (
  id: number,
  orderCouponUpdateReqRequest: BodyType<OrderCouponUpdateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderRes>(
    {
      url: `/orders/${id}/user-coupon/`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: orderCouponUpdateReqRequest
    },
    options
  )
}

export type OrdersUserCouponUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersUserCouponUpdate>>>
export type OrdersUserCouponUpdateMutationBody = BodyType<OrderCouponUpdateReqRequest>
export type OrdersUserCouponUpdateMutationError = ErrorType<OrderPartialUpdate400Exception>

export const useOrdersUserCouponUpdate = <
  TError = ErrorType<OrderPartialUpdate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersUserCouponUpdate>>,
    TError,
    { id: number; data: BodyType<OrderCouponUpdateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersUserCouponUpdate>>,
    { id: number; data: BodyType<OrderCouponUpdateReqRequest> }
  > = (props) => {
    const { id, data } = props ?? {}

    return ordersUserCouponUpdate(id, data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof ordersUserCouponUpdate>>,
    TError,
    { id: number; data: BodyType<OrderCouponUpdateReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 장바구니에서 주문 생성
 * @summary 장바구니에서 주문 생성
 */
export const ordersCartsCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrderRes>({ url: `/orders/carts/`, method: 'post' }, options)
}

export type OrdersCartsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersCartsCreate>>>

export type OrdersCartsCreateMutationError = ErrorType<OrderCreate400Exception>

export const useOrdersCartsCreate = <
  TError = ErrorType<OrderCreate400Exception>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof ordersCartsCreate>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof ordersCartsCreate>>, TVariables> = () => {
    return ordersCartsCreate(requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof ordersCartsCreate>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 문서 배달 주문을 전송합니다.
 * @summary 문서 배달 주문 생성
 */
export const ordersDocumentCreate = (
  orderDocumentReqRequest: BodyType<OrderDocumentReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderDocumentRes>(
    {
      url: `/orders/document/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: orderDocumentReqRequest
    },
    options
  )
}

export type OrdersDocumentCreateMutationResult = NonNullable<Awaited<ReturnType<typeof ordersDocumentCreate>>>
export type OrdersDocumentCreateMutationBody = BodyType<OrderDocumentReqRequest>
export type OrdersDocumentCreateMutationError = ErrorType<OrderDocument400Exception>

export const useOrdersDocumentCreate = <TError = ErrorType<OrderDocument400Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ordersDocumentCreate>>,
    TError,
    { data: BodyType<OrderDocumentReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ordersDocumentCreate>>,
    { data: BodyType<OrderDocumentReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return ordersDocumentCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof ordersDocumentCreate>>,
    TError,
    { data: BodyType<OrderDocumentReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 예상 경로를 제공합니다.
 * @summary 예상 경로 조회
 */
export const ordersEstimatePathRetrieve = (
  params: OrdersEstimatePathRetrieveParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderEstimatePathRes>({ url: `/orders/estimate-path/`, method: 'get', params, signal }, options)
}

export const getOrdersEstimatePathRetrieveQueryKey = (params: OrdersEstimatePathRetrieveParams) => [
  `/orders/estimate-path/`,
  ...(params ? [params] : [])
]

export type OrdersEstimatePathRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>
>
export type OrdersEstimatePathRetrieveInfiniteQueryError = ErrorType<OrderEstimatePath400Exception>

export const useOrdersEstimatePathRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>,
  TError = ErrorType<OrderEstimatePath400Exception>
>(
  params: OrdersEstimatePathRetrieveParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersEstimatePathRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>> = ({ signal }) =>
    ordersEstimatePathRetrieve(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type OrdersEstimatePathRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>>
export type OrdersEstimatePathRetrieveQueryError = ErrorType<OrderEstimatePath400Exception>

export const useOrdersEstimatePathRetrieve = <
  TData = Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>,
  TError = ErrorType<OrderEstimatePath400Exception>
>(
  params: OrdersEstimatePathRetrieveParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOrdersEstimatePathRetrieveQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>> = ({ signal }) =>
    ordersEstimatePathRetrieve(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof ordersEstimatePathRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 결제 상태 조회
 * @summary 결제 상태 조회
 */
export const paymentsStatusRetrieve = (
  orderNumber: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransactionPaymentStatusRes>(
    { url: `/payments/${orderNumber}/status/`, method: 'get', signal },
    options
  )
}

export const getPaymentsStatusRetrieveQueryKey = (orderNumber: string) => [`/payments/${orderNumber}/status/`]

export type PaymentsStatusRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof paymentsStatusRetrieve>>>
export type PaymentsStatusRetrieveInfiniteQueryError = ErrorType<unknown>

export const usePaymentsStatusRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof paymentsStatusRetrieve>>,
  TError = ErrorType<unknown>
>(
  orderNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof paymentsStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPaymentsStatusRetrieveQueryKey(orderNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentsStatusRetrieve>>> = ({ signal }) =>
    paymentsStatusRetrieve(orderNumber, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof paymentsStatusRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!orderNumber,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type PaymentsStatusRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof paymentsStatusRetrieve>>>
export type PaymentsStatusRetrieveQueryError = ErrorType<unknown>

export const usePaymentsStatusRetrieve = <
  TData = Awaited<ReturnType<typeof paymentsStatusRetrieve>>,
  TError = ErrorType<unknown>
>(
  orderNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof paymentsStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPaymentsStatusRetrieveQueryKey(orderNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentsStatusRetrieve>>> = ({ signal }) =>
    paymentsStatusRetrieve(orderNumber, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof paymentsStatusRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!orderNumber,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 결제 결과 조회
 * @deprecated
 * @summary 결제 결과 조회
 */
export const paymentsOrderNumberStatusRetrieve = (
  orderNumber: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransactionPaymentResultRes>(
    { url: `/payments/order-number/${orderNumber}/status/`, method: 'get', signal },
    options
  )
}

export const getPaymentsOrderNumberStatusRetrieveQueryKey = (orderNumber: string) => [
  `/payments/order-number/${orderNumber}/status/`
]

export type PaymentsOrderNumberStatusRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>
>
export type PaymentsOrderNumberStatusRetrieveInfiniteQueryError = ErrorType<unknown>

export const usePaymentsOrderNumberStatusRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>,
  TError = ErrorType<unknown>
>(
  orderNumber: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPaymentsOrderNumberStatusRetrieveQueryKey(orderNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>> = ({ signal }) =>
    paymentsOrderNumberStatusRetrieve(orderNumber, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!orderNumber,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type PaymentsOrderNumberStatusRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>
>
export type PaymentsOrderNumberStatusRetrieveQueryError = ErrorType<unknown>

export const usePaymentsOrderNumberStatusRetrieve = <
  TData = Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>,
  TError = ErrorType<unknown>
>(
  orderNumber: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPaymentsOrderNumberStatusRetrieveQueryKey(orderNumber)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>> = ({ signal }) =>
    paymentsOrderNumberStatusRetrieve(orderNumber, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof paymentsOrderNumberStatusRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!orderNumber,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Tap으로 결제(SA만 가능)
 * @summary Tap으로 결제
 */
export const paymentsTapCreate = (
  tapPaymentPayReqRequest: BodyType<TapPaymentPayReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TapCardInitiatedRes>(
    {
      url: `/payments/tap/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tapPaymentPayReqRequest
    },
    options
  )
}

export type PaymentsTapCreateMutationResult = NonNullable<Awaited<ReturnType<typeof paymentsTapCreate>>>
export type PaymentsTapCreateMutationBody = BodyType<TapPaymentPayReqRequest>
export type PaymentsTapCreateMutationError = ErrorType<TapPaymentReq400Exception | TapPaymentReq404Exception>

export const usePaymentsTapCreate = <
  TError = ErrorType<TapPaymentReq400Exception | TapPaymentReq404Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentsTapCreate>>,
    TError,
    { data: BodyType<TapPaymentPayReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof paymentsTapCreate>>,
    { data: BodyType<TapPaymentPayReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return paymentsTapCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof paymentsTapCreate>>,
    TError,
    { data: BodyType<TapPaymentPayReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 유저 지갑으로 결제
 * @deprecated
 * @summary 유저 지갑으로 결제
 */
export const paymentsWalletCreate = (
  userWalletPaymentReqRequest: BodyType<UserWalletPaymentReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserWalletPaymentRes>(
    {
      url: `/payments/wallet/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userWalletPaymentReqRequest
    },
    options
  )
}

export type PaymentsWalletCreateMutationResult = NonNullable<Awaited<ReturnType<typeof paymentsWalletCreate>>>
export type PaymentsWalletCreateMutationBody = BodyType<UserWalletPaymentReqRequest>
export type PaymentsWalletCreateMutationError = ErrorType<unknown>

export const usePaymentsWalletCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof paymentsWalletCreate>>,
    TError,
    { data: BodyType<UserWalletPaymentReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof paymentsWalletCreate>>,
    { data: BodyType<UserWalletPaymentReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return paymentsWalletCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof paymentsWalletCreate>>,
    TError,
    { data: BodyType<UserWalletPaymentReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

export const paymentsWebhookTapCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/payments/webhook/tap/`, method: 'post' }, options)
}

export type PaymentsWebhookTapCreateMutationResult = NonNullable<Awaited<ReturnType<typeof paymentsWebhookTapCreate>>>

export type PaymentsWebhookTapCreateMutationError = ErrorType<unknown>

export const usePaymentsWebhookTapCreate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof paymentsWebhookTapCreate>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof paymentsWebhookTapCreate>>, TVariables> = () => {
    return paymentsWebhookTapCreate(requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof paymentsWebhookTapCreate>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * for health check
 */
export const pingRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<string>({ url: `/ping/`, method: 'get', signal }, options)
}

export const getPingRetrieveQueryKey = () => [`/ping/`]

export type PingRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof pingRetrieve>>>
export type PingRetrieveInfiniteQueryError = ErrorType<unknown>

export const usePingRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof pingRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPingRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pingRetrieve>>> = ({ signal }) =>
    pingRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type PingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pingRetrieve>>>
export type PingRetrieveQueryError = ErrorType<unknown>

export const usePingRetrieve = <
  TData = Awaited<ReturnType<typeof pingRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPingRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pingRetrieve>>> = ({ signal }) =>
    pingRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof pingRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 상품 상세 조회
 * @summary 상품 상세 조회
 */
export const productsRetrieve = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProductDetailRes>({ url: `/products/${id}/`, method: 'get', signal }, options)
}

export const getProductsRetrieveQueryKey = (id: number) => [`/products/${id}/`]

export type ProductsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof productsRetrieve>>>
export type ProductsRetrieveInfiniteQueryError = ErrorType<unknown>

export const useProductsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof productsRetrieve>>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProductsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof productsRetrieve>>> = ({ signal }) =>
    productsRetrieve(id, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type ProductsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof productsRetrieve>>>
export type ProductsRetrieveQueryError = ErrorType<unknown>

export const useProductsRetrieve = <TData = Awaited<ReturnType<typeof productsRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProductsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof productsRetrieve>>> = ({ signal }) =>
    productsRetrieve(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 상품 카테고리 목록 조회
 * @summary 상품 카테고리 목록 조회
 */
export const productsCategoriesList = (
  params: ProductsCategoriesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedProductCategoryResList>(
    { url: `/products/categories/`, method: 'get', params, signal },
    options
  )
}

export const getProductsCategoriesListQueryKey = (params: ProductsCategoriesListParams) => [
  `/products/categories/`,
  ...(params ? [params] : [])
]

export type ProductsCategoriesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof productsCategoriesList>>>
export type ProductsCategoriesListInfiniteQueryError = ErrorType<unknown>

export const useProductsCategoriesListInfinite = <
  TData = Awaited<ReturnType<typeof productsCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params: ProductsCategoriesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof productsCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProductsCategoriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof productsCategoriesList>>> = ({ signal }) =>
    productsCategoriesList(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof productsCategoriesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type ProductsCategoriesListQueryResult = NonNullable<Awaited<ReturnType<typeof productsCategoriesList>>>
export type ProductsCategoriesListQueryError = ErrorType<unknown>

export const useProductsCategoriesList = <
  TData = Awaited<ReturnType<typeof productsCategoriesList>>,
  TError = ErrorType<unknown>
>(
  params: ProductsCategoriesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof productsCategoriesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProductsCategoriesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof productsCategoriesList>>> = ({ signal }) =>
    productsCategoriesList(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof productsCategoriesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 상품 카테고리의 상품 목록 조회
 * @summary 상품 카테고리의 상품 목록 조회
 */
export const productsCategoriesProductsList = (
  params: ProductsCategoriesProductsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedProductCategoryProductsResList>(
    { url: `/products/categories/products/`, method: 'get', params, signal },
    options
  )
}

export const getProductsCategoriesProductsListQueryKey = (params: ProductsCategoriesProductsListParams) => [
  `/products/categories/products/`,
  ...(params ? [params] : [])
]

export type ProductsCategoriesProductsListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof productsCategoriesProductsList>>
>
export type ProductsCategoriesProductsListInfiniteQueryError = ErrorType<unknown>

export const useProductsCategoriesProductsListInfinite = <
  TData = Awaited<ReturnType<typeof productsCategoriesProductsList>>,
  TError = ErrorType<unknown>
>(
  params: ProductsCategoriesProductsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof productsCategoriesProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProductsCategoriesProductsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof productsCategoriesProductsList>>> = ({ signal }) =>
    productsCategoriesProductsList(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof productsCategoriesProductsList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type ProductsCategoriesProductsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof productsCategoriesProductsList>>
>
export type ProductsCategoriesProductsListQueryError = ErrorType<unknown>

export const useProductsCategoriesProductsList = <
  TData = Awaited<ReturnType<typeof productsCategoriesProductsList>>,
  TError = ErrorType<unknown>
>(
  params: ProductsCategoriesProductsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof productsCategoriesProductsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getProductsCategoriesProductsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof productsCategoriesProductsList>>> = ({ signal }) =>
    productsCategoriesProductsList(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof productsCategoriesProductsList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Push Token 등록/수정
 * @summary Push Token 등록/수정
 */
export const pushSubscriptionsCreate = (
  userPushSubscriptionReqRequest: BodyType<UserPushSubscriptionReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserPushSubscriptionRes>(
    {
      url: `/push-subscriptions/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userPushSubscriptionReqRequest
    },
    options
  )
}

export type PushSubscriptionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof pushSubscriptionsCreate>>>
export type PushSubscriptionsCreateMutationBody = BodyType<UserPushSubscriptionReqRequest>
export type PushSubscriptionsCreateMutationError = ErrorType<UserPushSubscriptionCreate400Exception>

export const usePushSubscriptionsCreate = <
  TError = ErrorType<UserPushSubscriptionCreate400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof pushSubscriptionsCreate>>,
    TError,
    { data: BodyType<UserPushSubscriptionReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof pushSubscriptionsCreate>>,
    { data: BodyType<UserPushSubscriptionReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return pushSubscriptionsCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof pushSubscriptionsCreate>>,
    TError,
    { data: BodyType<UserPushSubscriptionReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Push Token 등록/수정
 * @summary Push Token 등록/수정
 */
export const pushSubscriptionsUserSubscriptionsRetrieve = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<UserPushSubscriptionGetRes>(
    { url: `/push-subscriptions/user-subscriptions/`, method: 'get', signal },
    options
  )
}

export const getPushSubscriptionsUserSubscriptionsRetrieveQueryKey = () => [`/push-subscriptions/user-subscriptions/`]

export type PushSubscriptionsUserSubscriptionsRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>
>
export type PushSubscriptionsUserSubscriptionsRetrieveInfiniteQueryError = ErrorType<unknown>

export const usePushSubscriptionsUserSubscriptionsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPushSubscriptionsUserSubscriptionsRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>> = ({ signal }) =>
    pushSubscriptionsUserSubscriptionsRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>, TError, TData>(
    { queryKey, queryFn, ...queryOptions }
  ) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type PushSubscriptionsUserSubscriptionsRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>
>
export type PushSubscriptionsUserSubscriptionsRetrieveQueryError = ErrorType<unknown>

export const usePushSubscriptionsUserSubscriptionsRetrieve = <
  TData = Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPushSubscriptionsUserSubscriptionsRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>> = ({ signal }) =>
    pushSubscriptionsUserSubscriptionsRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof pushSubscriptionsUserSubscriptionsRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * QR코드 생성 API
 */
export const qrcodeCreate = (
  qrCodeReqRequest: BodyType<QrCodeReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<Blob>(
    {
      url: `/qrcode/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: qrCodeReqRequest,
      responseType: 'blob'
    },
    options
  )
}

export type QrcodeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof qrcodeCreate>>>
export type QrcodeCreateMutationBody = BodyType<QrCodeReqRequest>
export type QrcodeCreateMutationError = ErrorType<unknown>

export const useQrcodeCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof qrcodeCreate>>,
    TError,
    { data: BodyType<QrCodeReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof qrcodeCreate>>, { data: BodyType<QrCodeReqRequest> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return qrcodeCreate(data, requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof qrcodeCreate>>, TError, { data: BodyType<QrCodeReqRequest> }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 상점 목록 조회
 * @summary 상점 목록 조회
 */
export const shopsList = (
  params?: ShopsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedShopResList>({ url: `/shops/`, method: 'get', params, signal }, options)
}

export const getShopsListQueryKey = (params?: ShopsListParams) => [`/shops/`, ...(params ? [params] : [])]

export type ShopsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopsList>>>
export type ShopsListInfiniteQueryError = ErrorType<unknown>

export const useShopsListInfinite = <TData = Awaited<ReturnType<typeof shopsList>>, TError = ErrorType<unknown>>(
  params?: ShopsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsList>>> = ({ signal }) =>
    shopsList(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof shopsList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type ShopsListQueryResult = NonNullable<Awaited<ReturnType<typeof shopsList>>>
export type ShopsListQueryError = ErrorType<unknown>

export const useShopsList = <TData = Awaited<ReturnType<typeof shopsList>>, TError = ErrorType<unknown>>(
  params?: ShopsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsList>>> = ({ signal }) =>
    shopsList(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof shopsList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 상점 상세 조회
 * @summary 상점 상세 조회
 */
export const shopsRetrieve = (id: number, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ShopRetrieveRes>({ url: `/shops/${id}/`, method: 'get', signal }, options)
}

export const getShopsRetrieveQueryKey = (id: number) => [`/shops/${id}/`]

export type ShopsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopsRetrieve>>>
export type ShopsRetrieveInfiniteQueryError = ErrorType<ShopRetrieveRes403Exception>

export const useShopsRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof shopsRetrieve>>,
  TError = ErrorType<ShopRetrieveRes403Exception>
>(
  id: number,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsRetrieve>>> = ({ signal }) =>
    shopsRetrieve(id, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type ShopsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shopsRetrieve>>>
export type ShopsRetrieveQueryError = ErrorType<ShopRetrieveRes403Exception>

export const useShopsRetrieve = <
  TData = Awaited<ReturnType<typeof shopsRetrieve>>,
  TError = ErrorType<ShopRetrieveRes403Exception>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsRetrieve>>> = ({ signal }) =>
    shopsRetrieve(id, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof shopsRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 상점 발급 가능 쿠폰 리스트
 * @summary 상점 발급 가능 쿠폰 리스트
 */
export const shopsCouponsList = (
  id: number,
  params?: ShopsCouponsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedCouponResList>(
    { url: `/shops/${id}/coupons/`, method: 'get', params, signal },
    options
  )
}

export const getShopsCouponsListQueryKey = (id: number, params?: ShopsCouponsListParams) => [
  `/shops/${id}/coupons/`,
  ...(params ? [params] : [])
]

export type ShopsCouponsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof shopsCouponsList>>>
export type ShopsCouponsListInfiniteQueryError = ErrorType<unknown>

export const useShopsCouponsListInfinite = <
  TData = Awaited<ReturnType<typeof shopsCouponsList>>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: ShopsCouponsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof shopsCouponsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsCouponsListQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsCouponsList>>> = ({ signal }) =>
    shopsCouponsList(id, params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof shopsCouponsList>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type ShopsCouponsListQueryResult = NonNullable<Awaited<ReturnType<typeof shopsCouponsList>>>
export type ShopsCouponsListQueryError = ErrorType<unknown>

export const useShopsCouponsList = <TData = Awaited<ReturnType<typeof shopsCouponsList>>, TError = ErrorType<unknown>>(
  id: number,
  params?: ShopsCouponsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof shopsCouponsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getShopsCouponsListQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof shopsCouponsList>>> = ({ signal }) =>
    shopsCouponsList(id, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof shopsCouponsList>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 소셜 회원 가입
 * @summary 소셜 회원 가입
 */
export const signupSocialCreate = (
  socialSignupRequest: BodyType<SocialSignupRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<SocialSignupRes>(
    {
      url: `/signup/social/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: socialSignupRequest
    },
    options
  )
}

export type SignupSocialCreateMutationResult = NonNullable<Awaited<ReturnType<typeof signupSocialCreate>>>
export type SignupSocialCreateMutationBody = BodyType<SocialSignupRequest>
export type SignupSocialCreateMutationError = ErrorType<SocialSignup401Exception>

export const useSignupSocialCreate = <TError = ErrorType<SocialSignup401Exception>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof signupSocialCreate>>,
    TError,
    { data: BodyType<SocialSignupRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof signupSocialCreate>>,
    { data: BodyType<SocialSignupRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return signupSocialCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof signupSocialCreate>>,
    TError,
    { data: BodyType<SocialSignupRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

export const sitesList = (
  params?: SitesListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedSiteResList>({ url: `/sites/`, method: 'get', params, signal }, options)
}

export const getSitesListQueryKey = (params?: SitesListParams) => [`/sites/`, ...(params ? [params] : [])]

export type SitesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesList>>>
export type SitesListInfiniteQueryError = ErrorType<unknown>

export const useSitesListInfinite = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesList>>> = ({ signal }) =>
    sitesList(params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof sitesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type SitesListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesList>>>
export type SitesListQueryError = ErrorType<unknown>

export const useSitesList = <TData = Awaited<ReturnType<typeof sitesList>>, TError = ErrorType<unknown>>(
  params?: SitesListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesList>>> = ({ signal }) =>
    sitesList(params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof sitesList>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 사이트 바텀 시트 목록 조회합니다.

권한: 누구나
 * @summary 사이트 바텀 시트 목록 조회
 */
export const sitesBottomSheetsList = (
  siteSlug: string,
  params?: SitesBottomSheetsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SiteBottomSheetRes[]>(
    { url: `/sites/${siteSlug}/bottom-sheets/`, method: 'get', params, signal },
    options
  )
}

export const getSitesBottomSheetsListQueryKey = (siteSlug: string, params?: SitesBottomSheetsListParams) => [
  `/sites/${siteSlug}/bottom-sheets/`,
  ...(params ? [params] : [])
]

export type SitesBottomSheetsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesBottomSheetsList>>>
export type SitesBottomSheetsListInfiniteQueryError = ErrorType<unknown>

export const useSitesBottomSheetsListInfinite = <
  TData = Awaited<ReturnType<typeof sitesBottomSheetsList>>,
  TError = ErrorType<unknown>
>(
  siteSlug: string,
  params?: SitesBottomSheetsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesBottomSheetsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesBottomSheetsListQueryKey(siteSlug, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesBottomSheetsList>>> = ({ signal }) =>
    sitesBottomSheetsList(siteSlug, params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof sitesBottomSheetsList>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!siteSlug,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type SitesBottomSheetsListQueryResult = NonNullable<Awaited<ReturnType<typeof sitesBottomSheetsList>>>
export type SitesBottomSheetsListQueryError = ErrorType<unknown>

export const useSitesBottomSheetsList = <
  TData = Awaited<ReturnType<typeof sitesBottomSheetsList>>,
  TError = ErrorType<unknown>
>(
  siteSlug: string,
  params?: SitesBottomSheetsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesBottomSheetsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesBottomSheetsListQueryKey(siteSlug, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesBottomSheetsList>>> = ({ signal }) =>
    sitesBottomSheetsList(siteSlug, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof sitesBottomSheetsList>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!siteSlug,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 배차 가능 상태 조회
 * @summary 배차 가능 상태 조회
 */
export const sitesDispatchStatusRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<SiteDispatchStatusRes>({ url: `/sites/dispatch-status/`, method: 'get', signal }, options)
}

export const getSitesDispatchStatusRetrieveQueryKey = () => [`/sites/dispatch-status/`]

export type SitesDispatchStatusRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>
>
export type SitesDispatchStatusRetrieveInfiniteQueryError = ErrorType<unknown>

export const useSitesDispatchStatusRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesDispatchStatusRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>> = ({ signal }) =>
    sitesDispatchStatusRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type SitesDispatchStatusRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>
>
export type SitesDispatchStatusRetrieveQueryError = ErrorType<unknown>

export const useSitesDispatchStatusRetrieve = <
  TData = Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesDispatchStatusRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>> = ({ signal }) =>
    sitesDispatchStatusRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof sitesDispatchStatusRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 슬러그로 사이트 조회
 * @summary 슬러그로 사이트 조회
 */
export const sitesSlugRetrieve = (
  slug: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SiteRes>({ url: `/sites/slug/${slug}/`, method: 'get', signal }, options)
}

export const getSitesSlugRetrieveQueryKey = (slug: string) => [`/sites/slug/${slug}/`]

export type SitesSlugRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof sitesSlugRetrieve>>>
export type SitesSlugRetrieveInfiniteQueryError = ErrorType<unknown>

export const useSitesSlugRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof sitesSlugRetrieve>>,
  TError = ErrorType<unknown>
>(
  slug: string,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof sitesSlugRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesSlugRetrieveQueryKey(slug)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesSlugRetrieve>>> = ({ signal }) =>
    sitesSlugRetrieve(slug, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof sitesSlugRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type SitesSlugRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sitesSlugRetrieve>>>
export type SitesSlugRetrieveQueryError = ErrorType<unknown>

export const useSitesSlugRetrieve = <
  TData = Awaited<ReturnType<typeof sitesSlugRetrieve>>,
  TError = ErrorType<unknown>
>(
  slug: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof sitesSlugRetrieve>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesSlugRetrieveQueryKey(slug)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesSlugRetrieve>>> = ({ signal }) =>
    sitesSlugRetrieve(slug, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof sitesSlugRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!slug,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 사용자 토큰 갱신
 * @summary 사용자 토큰 갱신
 */
export const tokenRefreshCreate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<TokenRefreshRes>({ url: `/token/refresh/`, method: 'post' }, options)
}

export type TokenRefreshCreateMutationResult = NonNullable<Awaited<ReturnType<typeof tokenRefreshCreate>>>

export type TokenRefreshCreateMutationError = ErrorType<unknown>

export const useTokenRefreshCreate = <TError = ErrorType<unknown>, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof tokenRefreshCreate>>, TVariables> = () => {
    return tokenRefreshCreate(requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * 사용자 쿠폰 목록 조회
 * @summary 사용자 쿠폰 목록 조회
 */
export const usersCouponsList = (
  userPk: number,
  params?: UsersCouponsListParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PaginatedUserCouponResList>(
    { url: `/users/${userPk}/coupons/`, method: 'get', params, signal },
    options
  )
}

export const getUsersCouponsListQueryKey = (userPk: number, params?: UsersCouponsListParams) => [
  `/users/${userPk}/coupons/`,
  ...(params ? [params] : [])
]

export type UsersCouponsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersCouponsList>>>
export type UsersCouponsListInfiniteQueryError = ErrorType<unknown>

export const useUsersCouponsListInfinite = <
  TData = Awaited<ReturnType<typeof usersCouponsList>>,
  TError = ErrorType<unknown>
>(
  userPk: number,
  params?: UsersCouponsListParams,
  options?: {
    query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersCouponsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersCouponsListQueryKey(userPk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersCouponsList>>> = ({ signal }) =>
    usersCouponsList(userPk, params, requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersCouponsList>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!userPk,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type UsersCouponsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCouponsList>>>
export type UsersCouponsListQueryError = ErrorType<unknown>

export const useUsersCouponsList = <TData = Awaited<ReturnType<typeof usersCouponsList>>, TError = ErrorType<unknown>>(
  userPk: number,
  params?: UsersCouponsListParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof usersCouponsList>>, TError, TData>
    request?: SecondParameter<typeof customInstance>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersCouponsListQueryKey(userPk, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersCouponsList>>> = ({ signal }) =>
    usersCouponsList(userPk, params, requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof usersCouponsList>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!userPk,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 사용자 쿠폰 목록 조회
 * @summary 사용자 쿠폰 발급
 */
export const usersCouponsCreate = (
  userPk: number,
  userCouponReqRequest: BodyType<UserCouponReqRequest>,
  params?: UsersCouponsCreateParams,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<PaginatedUserCouponResList>(
    {
      url: `/users/${userPk}/coupons/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userCouponReqRequest,
      params
    },
    options
  )
}

export type UsersCouponsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersCouponsCreate>>>
export type UsersCouponsCreateMutationBody = BodyType<UserCouponReqRequest>
export type UsersCouponsCreateMutationError = ErrorType<unknown>

export const useUsersCouponsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof usersCouponsCreate>>,
    TError,
    { userPk: number; data: BodyType<UserCouponReqRequest>; params?: UsersCouponsCreateParams },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof usersCouponsCreate>>,
    { userPk: number; data: BodyType<UserCouponReqRequest>; params?: UsersCouponsCreateParams }
  > = (props) => {
    const { userPk, data, params } = props ?? {}

    return usersCouponsCreate(userPk, data, params, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof usersCouponsCreate>>,
    TError,
    { userPk: number; data: BodyType<UserCouponReqRequest>; params?: UsersCouponsCreateParams },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 사용자 무료 배송 횟수
 * @summary 사용자 무료 배송 횟수
 */
export const usersFreeDeliveryRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<FreeDeliveryRes>({ url: `/users/free-delivery/`, method: 'get', signal }, options)
}

export const getUsersFreeDeliveryRetrieveQueryKey = () => [`/users/free-delivery/`]

export type UsersFreeDeliveryRetrieveInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>
>
export type UsersFreeDeliveryRetrieveInfiniteQueryError = ErrorType<unknown>

export const useUsersFreeDeliveryRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersFreeDeliveryRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>> = ({ signal }) =>
    usersFreeDeliveryRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type UsersFreeDeliveryRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>>
export type UsersFreeDeliveryRetrieveQueryError = ErrorType<unknown>

export const useUsersFreeDeliveryRetrieve = <
  TData = Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersFreeDeliveryRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>> = ({ signal }) =>
    usersFreeDeliveryRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof usersFreeDeliveryRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 로그인 유저 프로필
 * @summary 로그인 유저 프로필
 */
export const usersProfileRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<MyPageRes>({ url: `/users/profile/`, method: 'get', signal }, options)
}

export const getUsersProfileRetrieveQueryKey = () => [`/users/profile/`]

export type UsersProfileRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersProfileRetrieve>>>
export type UsersProfileRetrieveInfiniteQueryError = ErrorType<unknown>

export const useUsersProfileRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof usersProfileRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersProfileRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersProfileRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersProfileRetrieve>>> = ({ signal }) =>
    usersProfileRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersProfileRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type UsersProfileRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersProfileRetrieve>>>
export type UsersProfileRetrieveQueryError = ErrorType<unknown>

export const useUsersProfileRetrieve = <
  TData = Awaited<ReturnType<typeof usersProfileRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof usersProfileRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersProfileRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersProfileRetrieve>>> = ({ signal }) =>
    usersProfileRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof usersProfileRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 유저 지갑 정보
 * @summary 유저 지갑 정보
 */
export const usersWalletRetrieve = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<UserWalletRes>({ url: `/users/wallet/`, method: 'get', signal }, options)
}

export const getUsersWalletRetrieveQueryKey = () => [`/users/wallet/`]

export type UsersWalletRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersWalletRetrieve>>>
export type UsersWalletRetrieveInfiniteQueryError = ErrorType<unknown>

export const useUsersWalletRetrieveInfinite = <
  TData = Awaited<ReturnType<typeof usersWalletRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersWalletRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersWalletRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersWalletRetrieve>>> = ({ signal }) =>
    usersWalletRetrieve(requestOptions, signal)

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersWalletRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

export type UsersWalletRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersWalletRetrieve>>>
export type UsersWalletRetrieveQueryError = ErrorType<unknown>

export const useUsersWalletRetrieve = <
  TData = Awaited<ReturnType<typeof usersWalletRetrieve>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof usersWalletRetrieve>>, TError, TData>
  request?: SecondParameter<typeof customInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getUsersWalletRetrieveQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersWalletRetrieve>>> = ({ signal }) =>
    usersWalletRetrieve(requestOptions, signal)

  const query = useQuery<Awaited<ReturnType<typeof usersWalletRetrieve>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * 유저 인증 API
 * @summary 유저 인증 요청
 */
export const verificationRequestCreate = (
  userRequestVerificationReqRequest: BodyType<UserRequestVerificationReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserRequestVerificationRes>(
    {
      url: `/verification/request/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userRequestVerificationReqRequest
    },
    options
  )
}

export type VerificationRequestCreateMutationResult = NonNullable<Awaited<ReturnType<typeof verificationRequestCreate>>>
export type VerificationRequestCreateMutationBody = BodyType<UserRequestVerificationReqRequest>
export type VerificationRequestCreateMutationError = ErrorType<UserRequestVerification400Exception>

export const useVerificationRequestCreate = <
  TError = ErrorType<UserRequestVerification400Exception>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verificationRequestCreate>>,
    TError,
    { data: BodyType<UserRequestVerificationReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verificationRequestCreate>>,
    { data: BodyType<UserRequestVerificationReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return verificationRequestCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof verificationRequestCreate>>,
    TError,
    { data: BodyType<UserRequestVerificationReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 유저 인증 API
 * @summary 유저 인증 확인
 */
export const verificationVerifyCreate = (
  userConfirmVerificationReqRequest: BodyType<UserConfirmVerificationReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserConfirmVerificationRes>(
    {
      url: `/verification/verify/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userConfirmVerificationReqRequest
    },
    options
  )
}

export type VerificationVerifyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof verificationVerifyCreate>>>
export type VerificationVerifyCreateMutationBody = BodyType<UserConfirmVerificationReqRequest>
export type VerificationVerifyCreateMutationError = ErrorType<unknown>

export const useVerificationVerifyCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof verificationVerifyCreate>>,
    TError,
    { data: BodyType<UserConfirmVerificationReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof verificationVerifyCreate>>,
    { data: BodyType<UserConfirmVerificationReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return verificationVerifyCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof verificationVerifyCreate>>,
    TError,
    { data: BodyType<UserConfirmVerificationReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 바우처 생성: 개발용으로 만들어진 API 입니다. PROD 환경에서는 동작하지 않습니다(403에러). 시험해볼 바우처 코드를 이 API를 통해 생성해서 사용하시면 됩니다.
 * @summary 바우처 생성
 */
export const vouchersCreate = (
  voucherCreateReqRequest: BodyType<VoucherCreateReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<VoucherRes>(
    {
      url: `/vouchers/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: voucherCreateReqRequest
    },
    options
  )
}

export type VouchersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof vouchersCreate>>>
export type VouchersCreateMutationBody = BodyType<VoucherCreateReqRequest>
export type VouchersCreateMutationError = ErrorType<unknown>

export const useVouchersCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vouchersCreate>>,
    TError,
    { data: BodyType<VoucherCreateReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vouchersCreate>>,
    { data: BodyType<VoucherCreateReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return vouchersCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof vouchersCreate>>,
    TError,
    { data: BodyType<VoucherCreateReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 바우처 등록: 바우처를 포인트로 교환
 * @summary 바우처 등록
 */
export const vouchersRegisterCreate = (
  voucherRegisterReqRequest: BodyType<VoucherRegisterReqRequest>,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<VoucherRegisterRes>(
    {
      url: `/vouchers/register/`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: voucherRegisterReqRequest
    },
    options
  )
}

export type VouchersRegisterCreateMutationResult = NonNullable<Awaited<ReturnType<typeof vouchersRegisterCreate>>>
export type VouchersRegisterCreateMutationBody = BodyType<VoucherRegisterReqRequest>
export type VouchersRegisterCreateMutationError = ErrorType<unknown>

export const useVouchersRegisterCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof vouchersRegisterCreate>>,
    TError,
    { data: BodyType<VoucherRegisterReqRequest> },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof vouchersRegisterCreate>>,
    { data: BodyType<VoucherRegisterReqRequest> }
  > = (props) => {
    const { data } = props ?? {}

    return vouchersRegisterCreate(data, requestOptions)
  }

  return useMutation<
    Awaited<ReturnType<typeof vouchersRegisterCreate>>,
    TError,
    { data: BodyType<VoucherRegisterReqRequest> },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * 소셜 회원 탈퇴
 * @summary 소셜 회원 탈퇴
 */
export const withdrawalSocialUpdate = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<string>({ url: `/withdrawal/social/`, method: 'put' }, options)
}

export type WithdrawalSocialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof withdrawalSocialUpdate>>>

export type WithdrawalSocialUpdateMutationError = ErrorType<unknown>

export const useWithdrawalSocialUpdate = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof withdrawalSocialUpdate>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof customInstance>
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof withdrawalSocialUpdate>>, TVariables> = () => {
    return withdrawalSocialUpdate(requestOptions)
  }

  return useMutation<Awaited<ReturnType<typeof withdrawalSocialUpdate>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  )
}
